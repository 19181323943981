import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'home',
        meta: {requiresAuth: true},
        component: () => import('../views/HomeView.vue'),
      },
    ],
  },
  {
    path: '/sales',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'sales',
        meta: {requiresAuth: true},
        component: () => import('../views/SalesReportView.vue'),
      },
    ],
  },
  {
    path: '/customers',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'customers',
        meta: {requiresAuth: true},
        component: () => import('../views/CustomersView.vue'),
      },
    ],
  },
  {
    path: '/sales/:id',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'sale-details',
        meta: {requiresAuth: true},
        component: () => import('../views/SaleDetailsView.vue'),
      },
    ],
  },
  {
    path: '/agents',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'agents',
        meta: {requiresAuth: true},
        component: () => import('../views/AgentsReportView.vue'),
      },
    ],
  },
  {
    path: '/closing',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'closing',
        meta: {requiresAuth: true},
        component: () => import('../views/ClosingView.vue'),
      },
    ],
  },
  {
    path: '/rates',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'rates',
        meta: {requiresAuth: true},
        component: () => import('../views/RatesView.vue'),
      },
    ],
  },
  {
    path: '/productsreport',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'productsreport',
        meta: {requiresAuth: true},
        component: () => import('../views/ProductsReportView.vue'),
      },
    ],
  },
  {
    path: '/storesreport',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'storesreport',
        meta: {requiresAuth: true},
        component: () => import('../views/StoresReportView.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('../components/Layouts/PublicLayout.vue'),
    meta: { guest: true },
    children: [
      {
        path: '',
        name: 'login',
        meta: { guest: true },
        component: () => import('../views/LoginView.vue'),
      },
    ],
  },
  {
    path: '/categories',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'categories',
        meta: {requiresAuth: true},
        component: () => import('../views/CategoriesView.vue'),
      },
    ],
  },
  {
    path: '/operations',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'operations',
        meta: {requiresAuth: true},
        component: () => import('../views/OperationsView.vue'),
      },
    ],
  },
  {
    path: '/stock',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'stock',
        meta: {requiresAuth: true},
        component: () => import('../views/StockView.vue'),
      },
    ],
  },
  {
    path: '/products',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'products',
        meta: {requiresAuth: true},
        component: () => import('../views/ProductsView.vue'),
      },
    ],
  },
  {
    path: '/catalog',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'catalog',
        meta: {requiresAuth: true},
        component: () => import('../views/CatalogView.vue'),
      },
    ],
  },
  {
    path: '/subscriptions',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: "subscriptions",
        meta: {requiresAuth: true},
        component: () => import('../views/SubscriptionsView.vue'),
      },
    ],
  },
  {
    path: '/renewals',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: "renewals",
        meta: {requiresAuth: true},
        component: () => import('../views/RenewalsView.vue'),
      },
    ],
  },
  {
    path: '/account',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: "account",
        meta: {requiresAuth: true},
        component: () => import('../views/AccountView.vue'),
      },
    ],
  },
  {
    path: '/stores',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: "stores",
        meta: {requiresAuth: true},
        component: () => import('../views/StoresView.vue'),
      },
    ],
  },
  {
    path: '/users',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'users',
        meta: {requiresAuth: true},
        component: () => import('../views/UsersView.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/users");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
