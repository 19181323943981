import axios from 'axios';

const host = window.location.host;
const parts = host.split('.');

const state = {
  user: null,
  token: null,
  schema: null
};
const getters = {
  isAuthenticated: state => !!state.user,    
  StateUser: state => state.user,
  StateToken: state => state.token,
  StateSchema: state => state.schema
};

const actions = {
  async LogIn({commit}, User) {
    let user = {}
    Object.assign(axios.defaults.headers, {"solgc-client" : parts[0] });
    await axios.post('/auth/login', User).then(function(response){
      user = response.data

    }).catch(function(error){
      console.log(error.response)
    })
    
	await commit('setUser', user)
  },
  async LogOut({commit}){
    let user = null
    commit('LogOut', user)
  }, 
};
const mutations = {
  async setUser(state, user){
    state.user = user.user
    state.token = user.api_token
    state.schema = parts[0]
    var authentication = { user: user.user, token: user.api_token, schema: parts[0] }
    localStorage.setItem("authentication", JSON.stringify(authentication))
    Object.assign(axios.defaults.headers, {"api-token" : authentication.token });
    
  },
  LogOut(state){
    state.user = null
    state.token = null
    state.schema = null
    Object.assign(axios.defaults.headers, {"solgc-client" : 'solgc'});
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
